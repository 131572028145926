.clipper {
  position: absolute;
  top: 0;
  right: calc(-1 * var(--one-spacer-8));
  z-index: 1;
  transform: translateY(calc((100% - 5px) * -1));
  padding: var(--one-spacer-4) 0;
  background-color: var(--one-color-light-blue-100);
  border: 1px solid var(--one-color-white);
  border-radius: 100px;
  display: flex;

  .clipperIcon {
    transform: rotate(90deg);
    color: var(--one-color-blue-900);
  }
}
