@import "~@one/icons/dist/one-icons-legacy.css";
@import "~@one/icons/dist/one-icons-outlined.css";
@import "~@one/icons/dist/one-icons-filled.css";

.card-flat {
  box-shadow: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

owc-input {
  width: 100%;
}

owc-input[disabled] [slot="error-text"] {
  color: var(--one-color-interaction-default-negative-1);
}

owc-input[variant="outlined"] {
  box-shadow: inset 0 0 0 0.0625rem var(--one-color-blue-300);
}

owc-input[show-char-count][max-length] {
  margin-bottom: 16px !important;
}

owc-select-dropdown[outlined] {
  --one-color-interaction-default-neutral-3: var(--one-color-blue-300);
}

owc-select-dropdown[outlined]:focus,
owc-select-dropdown[outlined]:hover,
owc-select-dropdown[outlined]:focus-within,
owc-select-dropdown[outlined].select-dropdown-has-focus {
  background-color: var(--one-color-interaction-default-base-1);
}

owc-select-dropdown[outlined] * {
  --one-color-interaction-default-neutral-3: var(--one-color-legacy-gray-600);
}

owc-grid[item] {
  align-self: flex-start;
}

owc-table {
  box-shadow: none;
}

owc-table-row[selectable="false"]:hover,
owc-table-row[selectable="false"]:hover:focus {
  --cell-background-color: var(--one-color-interaction-hover-brand-3);
}

owc-table-row[selectable="false"]:focus:not(:hover) {
  --cell-background-color: var(--one-color-interaction-default-base-1);
}

owc-list-item[selected="false"]:focus {
  background-color: var(--one-color-light-blue-300);
}

owc-list-item[selected]:not([selected="false"]) {
  color: var(--one-color-interaction-active-brand-1);
  background-color: var(--one-color-light-blue-300);
}
