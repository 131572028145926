owc-expandable[variant="dense"].collapse {
  width: 100%;

  & [slot="title"] {
    padding: 0;
    flex-grow: 1;
  }

  & [slot="control"] {
    order: 1;
    align-self: center;

    owc-button {
      --content-padding: var(--one-spacer-8);
      background-color: transparent;
      box-shadow: none;
      .controlWrapper {
        display: flex;
        align-items: center;
        gap: var(--one-spacer-8);
      }
      &,
      owc-icon {
        color: var(--one-color-blue-900);
      }
    }
  }
}
