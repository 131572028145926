.cardContent {
  margin: 0;
  flex-grow: 1;
}
.card {
  padding: var(--one-spacer-8);
}
.mainTitle {
  padding: var(--one-spacer-4) var(--one-spacer-8);
}
.subTitle {
  padding: var(--one-spacer-8);
}
.grid {
  padding: var(--one-spacer-8);

  &.gridBlue {
    background-color: var(--one-color-light-blue-100);

    owc-typography[variant="caption"] {
      color: var(--one-color-gray-600);
    }

    .step:not(.warning) & .accent:not(.warningAccent) {
      color: var(--one-color-blue-900);
    }
  }

  owc-grid[item] {
    align-self: center;
  }

  .step.warning & {
    background-color: var(--one-color-yellow-a-100);
  }
}
.flexLine {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.keepAlign {
  > owc-typography[variant="body1"] {
    flex: 0 1 auto;
  }
  > owc-typography[variant="title6"] {
    justify-content: flex-end;
    display: inline-flex;
    flex: 0 1 auto;
    white-space: nowrap;
    padding-left: var(--one-spacer-4);
  }
}

.step + .step {
  border-top: 1px solid var(--one-color-gray-300);
}

.warningAccent {
  color: var(--one-color-legacy-accent-secondary-800);
}
