.container {
  display: grid;
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap);
  grid-template-columns: repeat(var(--grid-col-template), minmax(0, 1fr));
}
.item {
  grid-column: span var(--grid-size) / span var(--grid-size);
  display: grid;
  align-self: flex-start;
}
