.cardContent {
  margin: 0;
  flex-grow: 1;
  padding: 1px 0;
}

.diluentMaterial {
  display: flex;
  gap: var(--one-spacer-16);
}
