.groupCollapse {
  [slot="content"] {
    padding: 0;
  }
  [slot="title"] {
    align-self: center;
  }
}

.reagent + .reagent {
  border-top: 1px solid var(--one-color-gray-300);
  padding-top: var(--one-spacer-8);
  padding-bottom: var(--one-spacer-8);
}

.subInfo {
  color: var(--one-color-gray-800);
  margin-left: var(--one-spacer-4);
}

.groupRow {
  border-bottom: 1px solid var(--one-color-gray-300);
  padding-bottom: var(--one-spacer-8);
  margin-bottom: var(--one-spacer-16);
}

.comment {
  margin-top: var(--one-spacer-8);
}

.commentContent {
  color: var(--one-color-gray-800);
}
