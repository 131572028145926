.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-16);
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-32);
}
.cardContent {
  margin: 0;
  flex-grow: 1;
  overflow: visible;
}
.columnInside {
  display: flex;
  gap: var(--one-spacer-16);
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--one-spacer-12);
}
.infoWithBtnSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  display: flex;
  align-items: center;
}
.subtitle {
  margin-left: var(--one-spacer-4);
  color: var(--one-color-gray-600);
}
