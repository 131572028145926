.cardContent {
  margin: 0;
  flex-grow: 1;
}
.card {
  padding: var(--one-spacer-8);
}
.mainTitle {
  padding: var(--one-spacer-4) var(--one-spacer-8);
}
.subTitleMaterial {
  padding: var(--one-spacer-8);
}
.subTitleDiluent {
  padding: var(--one-spacer-8);
  padding-top: var(--one-spacer-24);
}
.grid {
  padding: var(--one-spacer-8);
  &.gridBlue {
    background-color: var(--one-color-light-blue-100);
  }

  owc-typography[variant="caption"] {
    color: var(--one-color-gray-600);
  }

  owc-typography {
    word-break: break-word;
  }
}
.volume {
  color: var(--one-color-blue-900);
}

.toRight {
  justify-self: flex-end;
}

.gray800 {
  color: var(--one-color-gray-800);
}

.material + .material {
  border-top: 1px solid var(--one-color-gray-300);
  padding-top: var(--one-spacer-8);
  padding-bottom: var(--one-spacer-8);
}
