.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 var(--one-spacer-16);
}
.value {
  font-weight: 500;
}
