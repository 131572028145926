.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-32);
}

.material {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-8);
}

.workflow {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-16);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--one-spacer-16);
}

.addMaterialButton {
  flex-grow: 1;
}
