.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoButton {
  box-shadow: none;
  background: transparent;
  min-width: 0;
  padding: 0;
  --content-padding: 0.5rem;
}

.authContent {
  display: flex;
  align-items: center;
  margin-right: var(--one-spacer-12);
}

.textLogo {
  margin-left: var(--one-spacer-12);
  color: var(--one-color-gray-800);
}

.header {
  display: flex;
  flex-direction: column;
  background: var(--one-color-background-base-1);
  flex-shrink: 0;
}

.toolbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--one-spacer-4) var(--one-spacer-16);
  min-height: 3rem;
}
