.waringInfo {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-4);
  border-radius: var(--one-border-radius-4);
  background-color: var(--one-color-yellow-a-100);
  padding: var(--one-spacer-8);
}
.waringInfoTitle {
  align-items: center;
  owc-icon[variant="warning"] {
    color: var(--one-color-legacy-accent-secondary-800);
  }
  display: flex;
  gap: var(--one-spacer-4);
  margin-bottom: var(--one-spacer-4);
}
.waringInfoDetailsRow {
  display: flex;
  gap: var(--one-spacer-8);
  align-items: center;
}
