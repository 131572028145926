owc-table.table {
  // --table-height: auto;
  owc-table-header {
    owc-table-header-cell {
      padding: var(--one-spacer-8) var(--one-spacer-16);

      background-color: var(--one-color-blue-300);
      box-shadow: inset 0 -1px 0 var(--one-color-blue-500);
    }
  }

  owc-table-row {
    owc-table-cell {
      padding: var(--one-spacer-8) var(--one-spacer-16);
      owc-typography {
        display: inline-block;
        font-weight: 500;
      }
    }
  }
}

.time {
  color: var(--one-color-gray-600);
  margin-left: var(--one-spacer-4);
}
.addRowbtn {
  border: 1px dashed var(--one-color-blue-900);
  border-radius: var(--one-border-radius-4);
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: visible;
  background: var(--one-color-gray-50);
  display: flex;
  justify-content: center;
  align-items: center;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  cursor: pointer;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:hover {
    background-color: var(--one-color-blue-100);
  }
}
.addRow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--one-spacer-16);
}

.addText {
  color: var(--one-color-blue-900);
}
