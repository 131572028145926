.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--one-spacer-12);
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-32);
}
.section {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-8);
}
.title {
  display: flex;
  align-items: center;
}
.subtitle {
  margin-left: var(--one-spacer-4);
  color: var(--one-color-gray-600);
}
.mainBox {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-16);
}
.panelMemberCollapse {
  [slot="content"] {
    padding: 0;
  }
  [slot="title"] {
    align-self: center;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--one-spacer-16);
}
.card {
  padding: var(--one-spacer-8);
}
.cardContent {
  margin: 0;
  flex-grow: 1;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--one-spacer-16);
}

.materialsCard {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-8);
}
