.card {
  padding: var(--one-spacer-8);
}

.cardContent {
  margin: 0;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-16);

  > owc-divider {
    margin: 0;
    padding: 0 var(--one-spacer-8);
  }
}
