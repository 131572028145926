.wrapper {
  display: flex;
  height: 3rem;
  owc-button {
    --content-padding: calc(2px + var(--one-spacer-8)) var(--one-spacer-12)
      calc(var(--one-spacer-8) - 2px) var(--one-spacer-12);
    min-width: auto;

    owc-icon {
      color: inherit;
    }

    &[variant="primary"] {
      box-shadow: inset 0 0 0 0.0625rem var(--one-color-blue-300);
      background-color: var(--one-color-blue-300);
      color: var(--one-color-blue-900);
    }

    &[variant="secondary"] {
      box-shadow: inset 0 0 0 0.0625rem var(--one-color-blue-300);
      color: var(--one-color-gray-600);
    }
  }
  owc-button:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  owc-button:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}
