.input.input {
  margin-top: -14px;
}
.groupName {
  display: flex;
  gap: var(--one-spacer-8);
  align-items: center;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-8);
}
