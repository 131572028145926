.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--one-spacer-12);
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-32);
}
.preDilution {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-8);
}
.title {
  display: flex;
  align-items: center;
}
.subtitle {
  margin-left: var(--one-spacer-4);
  color: var(--one-color-gray-600);
}
.workflow {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-16);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.primary {
  color: var(--one-color-blue-900);
}

.gray800 {
  color: var(--one-color-gray-800);
}

.gray600 {
  color: var(--one-color-gray-600);
}

.flexBetween {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  & > :last-child {
    white-space: nowrap;
  }
}

.middleWithInput {
  padding-top: var(--one-spacer-12);
}

.banner {
  padding: var(--one-spacer-12) var(--one-spacer-20);
  display: flex;
  align-items: center;
  gap: var(--one-spacer-8);
  color: var(--one-color-gray-800);
  background-color: var(--one-color-yellow-a-100);
  border: 1px solid var(--one-color-yellow-a-300);
  border-radius: var(--one-border-radius-4);

  owc-icon {
    color: inherit;
  }
}
