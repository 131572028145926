.cardContent {
  margin: 0;
  flex-grow: 1;
}
.buttons {
  padding: 1px;
}

.addPredilutionButton {
  margin: 2px;
}
