.label {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: var(--one-spacer-4) var(--one-spacer-8);

  background-color: var(--one-color-light-blue-100);
  color: var(--one-color-blue-900);

  border-radius: var(--one-border-radius-32);
}

.iconWrapper {
  position: absolute;

  right: -18px;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
}

.icon {
  color: var(--one-color-light-blue-500);
}
