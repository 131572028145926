.warning {
  background-color: var(--one-color-yellow-a-100);
}
.warningFactor {
  display: flex;
  align-items: center;
  gap: var(--one-spacer-4);
  owc-icon[variant="warning"] {
    color: var(--one-color-legacy-accent-secondary-800);
  }
}

.warningAccent {
  color: var(--one-color-legacy-accent-secondary-800);
  font-weight: 500;
}

.target {
  overflow-x: hidden;
  padding: var(--one-spacer-8);
}
.warningInfo {
  padding-top: var(--one-spacer-16);
}
