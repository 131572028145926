.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--one-color-blue-100);
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
