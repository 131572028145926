.main {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-24);
  padding: 0 var(--one-spacer-24);
}
.list {
  display: grid;
  gap: var(--one-spacer-20);

  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(150px, 1fr);
}
.card {
  position: relative;
  padding: var(--one-spacer-8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cardBtn {
  border: 1px solid var(--one-color-blue-300);
  border-radius: var(--one-border-radius-4);
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: var(--one-color-white);

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  cursor: pointer;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.addBtn {
  background-color: transparent;
  border: 1px dashed var(--one-color-blue-900);
}

.infoBtn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  justify-content: space-between;
}
.infoBtnContent {
  flex-grow: 1;
  justify-content: space-between;
}
.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-right: -6px;
}
.dateSection {
  display: flex;
  align-items: center;
}
.cardBody {
  padding-top: var(--one-spacer-12);
  padding-bottom: var(--one-spacer-16);

  owc-typography {
    word-break: break-word;
  }
}
.time {
  color: var(--one-color-gray-600);
  margin-left: var(--one-spacer-4);
}
.title {
  color: var(--one-color-blue-900);
  padding-bottom: var(--one-spacer-4);
}
.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--one-spacer-24);
  padding-bottom: var(--one-spacer-24);
}
.filteringSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--one-spacer-16);
  & owc-input {
    width: auto;
    margin-top: 0;
  }
}
.loadMore {
  align-self: center;
  --content-padding: var(--one-spacer-8) 0 var(--one-spacer-8)
    var(--one-spacer-8);
}
.loadingCard {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  inset: 0;
}
.footer {
  display: flex;
  gap: var(--one-spacer-4);
  justify-content: center;
  align-items: center;
  padding: var(--one-spacer-8);
  width: 100%;

  owc-icon,
  owc-typography {
    color: currentColor;
    font-weight: 500;
    line-height: 1.25rem;
  }

  owc-icon {
    font-size: 1.25rem;
  }
}

.statusBlue {
  background-color: var(--one-color-blue-300);
  color: var(--one-color-blue-900);
}
.statusGray {
  background-color: var(--one-color-gray-300);
  color: var(--one-color-gray-900);
}

.statusGreen {
  background-color: var(--one-color-green-300);
  color: var(--one-color-green-900);
}
