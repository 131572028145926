.large {
  min-height: 3rem;
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: var(--one-spacer-8);
  margin-left: calc(-1 * var(--one-spacer-12));

  owc-icon {
    color: inherit;
  }
}

.fullWidth {
  width: 100%;
}

.colorError {
  color: var(--one-color-interaction-default-negative-1);
}

.colorPrimary {
  &,
  owc-icon {
    color: var(--one-color-blue-900);
  }
}

.text {
  --content-padding: var(--one-spacer-8);
  background-color: transparent;
  box-shadow: none;
  .wrapper {
    display: flex;
    align-items: center;
    gap: var(--one-spacer-8);
  }
}

.secondaryBorder {
  box-shadow: 0 0 0 0.0625rem var(--one-color-blue-900);
}
