.mainTitle {
  padding: var(--one-spacer-4) var(--one-spacer-8);
}
.subTitle {
  padding: var(--one-spacer-8);
}
.grid {
  padding: var(--one-spacer-8);

  &.gridBlue {
    background-color: var(--one-color-light-blue-100);
    owc-typography[variant="caption"] {
      color: var(--one-color-gray-600);
    }
  }

  owc-grid[item] {
    align-self: center;
  }
}

.flexLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.keepAlign {
  > owc-typography[variant="body1"] {
    flex: 0 1 auto;
  }
  > owc-typography[variant="title6"] {
    justify-content: flex-end;
    display: inline-flex;
    flex: 0 1 auto;
    white-space: nowrap;
    padding-left: var(--one-spacer-4);
  }
}
.insideFlexBaseline {
  display: flex;
  gap: var(--one-spacer-8);
  align-items: baseline;
}
.insideFlexLine0 {
  display: flex;
  align-items: center;
  > owc-typography[variant="badge"] {
    padding-top: var(--one-spacer-4);
    padding-bottom: var(--one-spacer-4);
    color: var(--one-color-gray-600);
  }
  > owc-icon {
    font-size: 1rem;
  }
}
.multipleInColumn {
  align-items: flex-start;
}

.panel + .panel {
  border-top: 1px solid var(--one-color-gray-300);
}
.warning {
  background-color: var(--one-color-yellow-a-100);
}
.warningAccent {
  color: var(--one-color-legacy-accent-secondary-800);
}
.wrapTypography {
  owc-typography {
    word-break: break-word;
  }
}
