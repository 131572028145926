.banner {
  margin-top: var(--one-spacer-12);
  padding: var(--one-spacer-12) var(--one-spacer-20);
  display: flex;
  align-items: center;
  gap: var(--one-spacer-8);
  color: var(--one-color-gray-800);
  background-color: var(--one-color-yellow-a-100);
  border: 1px solid var(--one-color-yellow-a-300);
  border-radius: var(--one-border-radius-4);

  owc-icon {
    color: inherit;
  }
}
