.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-32);
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-8);
}

.title {
  display: flex;
  align-items: center;
}

.subtitle {
  margin-left: var(--one-spacer-4);
  color: var(--one-color-gray-600);
}

.mainBox {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-16);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--one-spacer-16);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--one-spacer-16);
}

.reagentsCard {
  display: flex;
  flex-direction: column;
  gap: var(--one-spacer-8);
}

.grid {
  padding: var(--one-spacer-8);
  &.gridBlue {
    background-color: var(--one-color-light-blue-100);
  }

  owc-typography[variant="caption"] {
    color: var(--one-color-gray-600);
  }
}

.card {
  padding: var(--one-spacer-8);
}

.cardContent {
  margin: 0;
  flex-grow: 1;
}

.cardTitle {
  padding: var(--one-spacer-12) 0 var(--one-spacer-20) var(--one-spacer-8);
}

.volume {
  color: var(--one-color-blue-900);
}

.toRight {
  text-align: right;
  justify-self: flex-end;
}

.warning {
  background-color: var(--one-color-yellow-a-100);
}
.warningAccent {
  color: var(--one-color-legacy-accent-secondary-800);
}
