.cardContent {
  margin: 0;
  flex-grow: 1;
  padding: 1px 0;
}

.materialConcentration {
  display: flex;
  gap: var(--one-spacer-8);

  > :first-child {
    flex: 1 1 auto;
  }
  > :last-child {
    flex: 2 2 auto;
  }
}

.stockMaterial {
  display: flex;
  gap: var(--one-spacer-16);
}

.unitFactor {
  display: flex;
  gap: var(--one-spacer-8);

  > * {
    flex: 1 1 100%;
  }
}

.withArrow {
  position: relative;

  .arrow {
    position: absolute;
    top: 24px;
    left: 0;
    transform: translateX(-37.5%) translateY(-50%);
  }
}

.inputSuffix {
  color: var(--one-color-interaction-default-neutral-3);
}
.buttons {
  display: flex;
  justify-content: flex-end;
  padding: 1px;
}
