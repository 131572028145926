.container {
  display: flex;
  flex-direction: column;
  padding: var(--one-spacer-24) 0;
  padding-bottom: var(--one-spacer-48);
  flex: 1 0 auto;
  width: 100%;

  &:not(.fullWidth) {
    max-width: var(--one-media-breakpoint-m-min);
    margin: 0 auto;
  }
}
